import * as React from 'react';
import {
  Popover,
  Typography,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ClickAwayListener,
  Popper,
  Paper,
  Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Loading from 'src/components/Loading/Loading';

import {
  getInventoryApi,
  getCategoryApi,
  getPackFreeApi,
  getTrendingApi,
  getPackDetailApi,
  SearchSticker
} from 'src/apis/socialStickersAPI';

import TabSocial from 'src/components/Tabs/TabSocial';
import _ from 'lodash';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) => ({
  rootPaper: {
    width: 300,
    height: 360,
    borderRadius: 10,
    overflow: 'hidden !important',
    zIndex: 30,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
  },
  paper: {
    width: '100%',
    overflow: 'hidden !important',
    height: 309
  },
  wrapIcon: {
    height: 38,
    width: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  wrapTab: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  wrapContent: {
    padding: '4px 8px'
  },
  wrapSticky: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      borderRadius: 10
    }
  },
  wrapCategory: {
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 40,
    width: 133,
    margin: '4px !important',
    padding: '0px 4px 0px 4px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapMenu: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 10px',
    '&:hover': {
      backgroundColor: theme.palette.button.inherit.hover,
      borderRadius: '8px'
    }
  }
}));

const BasicPopover = React.memo((props: any) => {
  const classes = useStyles();
  const {
    openPopper,
    setOpenPopper,
    anchorEl,
    setAnchorEl,
    setStickySelected,
    formik,
    setTypeFocus,
    inputRef,
    type
  } = props;
  const [listSticky, setListSticky] = React.useState<any>([]);
  const [listMenu, setListMenu] = React.useState<any>([
    { icon: 'fa-regular fa-fire-flame-curved', id: 'trending' },
    { icon: 'fa-regular fa-magnifying-glass fa-lg', id: 'search' }
    // { icon: 'fa-regular fa-clock-rotate-left fa-lg', id: 'history' }
  ]);
  const [listCategories, setListCategories] = React.useState<any>([]);
  const [keyTab, setKeyTab] = React.useState<any>('trending');
  const [keyword, setKeyword] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openPopperMenu, setOpenPopperMenu] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setKeyTab('trending');
    setOpenPopper(false);
  };
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorElMenu(event.currentTarget);
    setOpenPopperMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setOpenPopperMenu(false);
  };

  const open = Boolean(anchorEl);

  const fetchData = async (type: any, typeSticky = 'has') => {
    setIsLoading(true);
    let response;
    if (type === 'default') {
      response = await getInventoryApi();
      if (response.status === 200) {
        let { data } = response.data;
        setIsLoading(false);
        if (!data || !Object.keys(data).length) return;

        let menuPlus = data.plStickerPacks?.map((el: any) => ({
          sticky: el.thumbnails[0].url,
          id: el.id,
          listSticky: el.stickers?.map((item: any) => ({
            id: item.id,
            url: item.images[0].url
          }))
        }));

        if (menuPlus?.length) {
          setListMenu(prev => _.uniqBy([...prev, ...menuPlus], 'id'));
        }
      }
    } else if (type === 'category') {
      response = await getCategoryApi();
      if (response.status === 200) {
        if (!response.data.data || !Object.keys(response.data.data).length)
          return;
        setIsLoading(false);

        setListCategories(response.data.data);
      }
    } else if (type === 'trending') {
      response = await getTrendingApi();
      if (response.status === 200) {
        if (!response.data.data || !Object.keys(response.data.data).length)
          return;

        let listTrending = response.data.data?.map((el: any) => ({
          id: el.id,
          url: el.images[0].url
        }));
        setIsLoading(false);

        setListMenu(prev => [
          {
            ...prev[0],
            listSticky: listTrending
          },
          ...prev.slice(1)
        ]);
      }
    } else if (type === 'free') {
      response = await getPackFreeApi();

      if (response.status === 200) {
        let { data } = response.data;
        if (!data || !Object.keys(data).length) return;
        let listMenuFree = data?.map((el: any) => ({
          sticky: el.thumbnails[0].url,
          id: el.id,
          name: el.name[0].content
        }));
        setIsLoading(false);

        if (listMenuFree?.length) {
          setListMenu(prev => _.uniqBy([...prev, ...listMenuFree], 'id'));
        }
      }
    } else if (typeSticky === 'free') {
      setListSticky([]);
      response = await getPackDetailApi(type);
      if (response.status === 200) {
        let { data } = response.data;
        if (!data || !Object.keys(data).length) return;
        setIsLoading(false);

        let listSticky = data.stickers?.map((el: any) => ({
          id: el.id,
          url: el.images[0].url
        }));
        setListSticky(listSticky);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let newKeyValue = listMenu[newValue].id;
    setKeyTab(newKeyValue);
    setOpenPopperMenu(false);
    setAnchorElMenu(null);
  };

  React.useEffect(() => {
    fetchData('trending');
    fetchData('default');
    fetchData('category');
  }, []);

  React.useEffect(() => {
    setListSticky([]);
    if (keyTab && keyTab !== 'search') {
      let listSticky = listMenu?.find(
        (el: any) => el.id === keyTab
      )?.listSticky;
      if (listSticky) {
        setListSticky(listSticky);
      } else {
        fetchData(keyTab, 'free');
      }
    }
  }, [keyTab, JSON.stringify(listMenu)]);

  const handleSearchSticky = React.useCallback(
    _.debounce(async keyword => {
      setIsLoading(true);
      let response = await SearchSticker({ q: keyword });
      if (response.status === 200) {
        setIsLoading(false);
        let listSearch = response.data.data?.map((el: any) => ({
          id: el.id,
          url: el.thumbnails[0].url
        }));
        setListSticky(listSearch);
      }
    }, 400),
    []
  );
  React.useEffect(() => {
    if (keyword) {
      handleSearchSticky(keyword);
    } else if (keyTab === 'search' && keyword === '') {
      setListSticky([]);
    }
  }, [keyword, keyTab]);
  const handleGetDetailPack = async (id: any) => {
    try {
      setIsLoading(true);
      let response = await getPackDetailApi(id);
      if (response.status === 200) {
        let { data } = response.data;
        if (!data || !Object.keys(data).length) return;
        setIsLoading(false);

        let listSticky = data.stickers?.map((el: any) => ({
          id: el.id,
          url: el.images[0].url
        }));
        setListSticky(listSticky);
      }
    } catch (error) {
      console.log('err', error);
      setIsLoading(false);
    }
  };
  const MenuAction = React.memo((props: any) => {
    const classes = useStyles();
    const { anchorEl, openPopper, handleCloseMenu, listMenu, keyTab } = props;
    return (
      <ClickAwayListener
        onClickAway={() => {
          handleCloseMenu();
        }}
      >
        <Popper
          anchorEl={anchorEl}
          key="popper"
          open={openPopper}
          placement="bottom-end"
          disablePortal
          style={{
            padding: '5px 0px',
            zIndex: 1001
          }}
          id="scrollableDivStickyMorePopup"
          className={classes.rootPaper}
        >
          <Paper>
            {listMenu?.map((item: any) => (
              <Box
                sx={
                  keyTab === item.key
                    ? { backgroundColor: '#ecf3ff !important' }
                    : { width: 360, cursor: 'pointer' }
                }
                key={item.key}
                onClick={e => {
                  e.stopPropagation();
                  handleGetDetailPack(item.key);
                  handleCloseMenu();
                }}
              >
                <div className={classes.wrapMenu}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.image ? (
                      <ListItemIcon sx={{ color: '#000', minWidth: '40px' }}>
                        <img
                          src={item.image}
                          alt=""
                          width={24}
                          height={24}
                        ></img>
                      </ListItemIcon>
                    ) : null}
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 500, fontSize: '15px' }}
                    >
                      {item.label} <br />
                      <Typography
                        style={{ fontSize: '12px', color: '#65676b' }}
                        variant="body2"
                      >
                        {item.description}
                      </Typography>
                    </Typography>
                  </div>

                  {keyTab === item.key && (
                    <i
                      style={{ color: buttonColor.backgroundColor }}
                      className="fa-regular fa-check"
                    ></i>
                  )}
                </div>
              </Box>
            ))}
          </Paper>
        </Popper>
      </ClickAwayListener>
    );
  });

  return (
    <div>
      <ClickAwayListener
        onClickAway={() => {
          handleClose();
        }}
      >
        <Popper
          anchorEl={anchorEl}
          key="popper"
          open={openPopper}
          placement={type === 'chat' ? 'top-start' : 'top-end'}
          disablePortal
          style={{
            padding: '5px 0px',
            zIndex: 1001
          }}
          id="scrollableDivStickyPopup"
          className={classes.rootPaper}
        >
          <Paper key={'paper'}>
            {open ? (
              <div>
                <div className={classes.wrapTab}>
                  <TabSocial
                    value={keyTab}
                    styleTab={{
                      minHeight: '20px',
                      height: '38px',
                      padding: '0px 12px',
                      marginRight: 0
                    }}
                    style={{
                      borderBottom: `2px solid ${buttonColor.backgroundColor}`
                    }}
                    listTabs={listMenu
                      ?.filter((item: any) => !item.name)
                      ?.map((el: any) => ({
                        ...el,
                        text: (
                          <Typography
                            style={
                              keyTab === el.id
                                ? {
                                    color: buttonColor.backgroundColor
                                  }
                                : { color: '#65676B' }
                            }
                          >
                            {el.icon ? (
                              <i
                                style={
                                  keyTab === el.id
                                    ? {
                                        color: buttonColor.backgroundColor,
                                        fontSize: 22
                                      }
                                    : { color: '#65676B', fontSize: 22 }
                                }
                                className={el.icon}
                              ></i>
                            ) : el.sticky ? (
                              <img
                                style={{ height: 23, width: 23 }}
                                src={el.sticky}
                                alt=""
                              />
                            ) : (
                              ''
                            )}
                          </Typography>
                        )
                      }))}
                    handleChange={handleChange}
                  />
                  <div className={classes.iconRoot}>
                    <div
                      onClick={e => {
                        handleClick(e);
                        if (
                          !listMenu?.filter(
                            (el: any) => el.name && !el.listSticky
                          ).length
                        ) {
                          fetchData('free');
                        }
                      }}
                      className={classes.wrapIcon}
                    >
                      <i
                        style={
                          listMenu
                            ?.filter((el: any) => el.name && !el.listSticky)
                            ?.map((item: any) => item.id)
                            .includes(keyTab)
                            ? { color: buttonColor.backgroundColor }
                            : { color: '#65676b' }
                        }
                        className="fa-solid fa-caret-down"
                      ></i>
                    </div>
                  </div>
                </div>
                {keyTab === 'search' && (
                  <div style={{ padding: '0px 8px', height: 40 }}>
                    <InputSearch
                      label="Tìm kiếm..."
                      keyword={keyword}
                      setKeyword={setKeyword}
                    />
                  </div>
                )}

                <PerfectScrollbar
                  options={{ suppressScrollX: true }}
                  style={{
                    width: '100%',
                    height: keyTab === 'search' ? 272 : 312
                  }}
                >
                  <Grid
                    className={classes.wrapContent}
                    container
                    spacing={keyTab === 'search' ? 0 : 0.3}
                  >
                    {keyTab === 'search' && keyword.length < 1 ? (
                      listCategories?.map((el: any, index) => (
                        <div
                          key={index}
                          className={classes.wrapCategory}
                          style={{
                            backgroundColor: el.bgColor
                          }}
                          onClick={() => {
                            setKeyword(el.name);
                          }}
                        >
                          <img
                            alt=""
                            src={el.image[0].url}
                            width={24}
                            height={24}
                          />{' '}
                          &nbsp;
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              color: '#000000'
                            }}
                          >
                            {el.name}
                          </Typography>
                        </div>
                      ))
                    ) : listSticky.length < 1 || isLoading ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '250px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <Typography>không có nhãn để hiển thị</Typography>
                        )}
                      </Box>
                    ) : (
                      !isLoading &&
                      listSticky?.map((el: any) => (
                        <Grid
                          onClick={() => {
                            if (formik) {
                              formik.setFieldValue('display_sticky', el?.url);
                            }
                            setStickySelected(el);
                            setTypeFocus && setTypeFocus('gif');
                            handleClose();
                            inputRef?.current?.focus();
                          }}
                          className={classes.wrapSticky}
                          key={el.id}
                          item
                          xs={3}
                        >
                          <img
                            style={{ width: '60px', height: '60px' }}
                            src={el.url}
                            alt=""
                          />
                        </Grid>
                      ))
                    )}
                  </Grid>
                </PerfectScrollbar>
              </div>
            ) : null}
          </Paper>
        </Popper>
      </ClickAwayListener>

      {anchorElMenu &&
      listMenu?.filter((el: any) => el.name && !el.listSticky).length ? (
        <MenuAction
          keyTab={keyTab}
          anchorEl={anchorElMenu}
          openPopper={openPopperMenu}
          handleCloseMenu={handleCloseMenu}
          listMenu={listMenu
            ?.filter((el: any) => el.name && !el.listSticky)
            ?.map((item: any) => ({
              key: item.id,
              image: item.sticky,
              label: item.name,
              action: () => {
                setKeyTab(item.id);
                handleCloseMenu();
              }
            }))}
        />
      ) : null}
    </div>
  );
});

export default BasicPopover;
